html,
body {
	height         : 100%;
	/* scroll-behavior: smooth; */
}

#serviceWorkerUpdateCntr {
  position: fixed; /* Sit on top of the screen */
  z-index: 9; /* Add a z-index if needed */
  left: calc(50% - 200px); /* Center the snackbar */
  top: 100px; /* 30px from the bottom */
  width: 400px;
}

#app {
	display       : flex;
	flex-direction: column;
	min-height    : 100vh;
	min-width     : 1200px !important;
}

/**
 * Override min-width to prevent displaying smaller than Bootstrap 5's "xxl" size
 * Note: still using Bootstrap 4...
 */
.container {
	min-width: 1320px !important;
}

#content {
	padding-top: 56px;
}

.tight-gutters {
	margin-right: -3px;
	margin-left : -3px;
}

.tight-gutters>.col,
.tight-gutters>[class*="col-"] {
	padding-right: 3px;
	padding-left : 3px;
}

.form-login {
    max-width: 320px;
}

.twitter-typeahead {
	width: 100%;
}

.simple-label {
	font-weight: 400;
}

.location .form-vertical div.error {
	margin-left: 0;
}

.location form {
	padding-left: 16px;
}

.clickable {
	cursor: pointer;
}

.clickable.asc::after {
	font-family: FontAwesome;
	content: "\f062";
	margin-left: 0.25em;
}

.clickable.desc::after {
	font-family: FontAwesome;
	content: "\f063";
	margin-left: 0.25em;
}

/* Locations Picker */

#filterlocation {
	margin-bottom: 9px;
}

#location .locations_picker_wrapper .locations_picker {
	height  : 350px;
	border  : 1px solid #ccc;
	overflow: auto;
}

#location .locations_picker_wrapper .locations_picker ul {
	padding: 0;
	margin : 0;
}

#location .locations_picker_wrapper .locations_picker ul li {
	display   : list-item;
	float     : none;
	padding   : 0;
	cursor    : pointer;
	border-top: 1px solid #fff;
}

#location .locations_picker_wrapper .locations_picker>ul>li:before {
	font-family: FontAwesome;
	content    : "\f054";
	width      : 14px;
	text-align : center;
	float      : left;
}

#location .locations_picker_wrapper .locations_picker ul li:hover {
	background-color: #EDEDED;
}

#location .locations_picker_wrapper .locations_picker ul li input {
	margin-left : 2px;
	margin-right: 4px;
}

#location .locations_picker_wrapper .locations_picker ul ul {
	display   : none;
	background: #FFF;
	padding   : 0;
}

#location .locations_picker_wrapper .locations_picker ul li li {
	padding         : 0;
	background-image: none;
}

#location .locations_picker_wrapper .locations_picker ul li li input {
	margin-left: 30px;
}

#location .locations_picker_wrapper .locations_picker ul .selected {
	background-color: #F6F6F6;
}

#location .locations_picker_wrapper .locations_picker>ul>li.expanded:before {
	content: "\f078";
}

#location .locations_picker_wrapper .locations_picker ul .expanded ul {
	display: block;
}


/* ui loader */

.ui_loading_overlay {
	width     : 590px;
	background: #fff;
	opacity   : 0.65;
	filter    : alpha(opacity=65);
	z-index   : 600;
	position  : absolute;
	display   : block;
}

.ui_shadow {
	border    : 1px solid #000;
	box-shadow: 2px 2px 2px #666666;
}

#content #results table th {
	white-space: nowrap;
}

.table-striped tbody tr.unpublished td {
	background: #D2D2D2;
}

#content #results table,
#content table.bulkedit {
	font-size: 0.9em;
}

table.bulkedit th,
table.bulkedit td {
	padding: 3px;
}

table.bulkedit input,
table.bulkedit textarea,
table.bulkedit select {
	font-size: 12px;
	padding  : 1px;
	margin   : 0;
}

table.bulkedit .spread {
	width: 60px;
}

table.bulkedit .spread-wide {
	width: 90px;
}

table.file-list td {
	vertical-align: middle;
	line-height   : inherit;
}

table.file-list td.icon {
	text-align: center;
}

/* .media-item.placeholder .card .card-body {
	height: 112px;
} */

div.pagination {
	margin: 18px 10px;
}

#edit div.mass_override {
	border-bottom: 1px solid #ddd;
	background   : #fff;
	padding-left : 9px;
}

#edit div.mass_override.enabled {
	border-bottom: 1px solid #FCEEC1;
	background   : #FDF5D9;
	padding-left : 9px;
}

#edit div.mass_override .inputs-list li {
	width: 100%;
}

#edit div.mass_override .clearfix {
	margin-bottom: 0;
}

#edit .location ol.train_lines li span {
	cursor: pointer;
}

#edit .location ol.train_lines li span:hover {
	background: #cdd;
}

#edit .location ol.train_lines li input.mini,
#edit #panel-location ol.train_lines li input.mini {
	width  : 40px;
	padding: 0;
	margin : 0;
}

.table-striped tbody tr:hover td,
.table-striped tbody tr.unpublished:hover td {
	background-color: #d6d5ff;
}

.table th.highlight,
.table td.highlight {
	background-color: rgba(140, 198, 62, 0.15);
}

.table tr.staff:last-child th,
.table tr.staff:last-child td,
.table tr.division:last-child th,
.table tr.division:last-child td,
.table tr.inquiry-type:last-child th,
.table tr.inquiry-type:last-child td {
	border-bottom: none;
}

.table th.total,
.table td.total {
	background-color: #f5f5f5;
}

.table tr.total th,
.table tr.total td {
	border-top-color   : rgb(51, 51, 51);
	border-bottom-color: rgb(51, 51, 51);
	border-bottom-style: double;
	border-bottom-width: 3px;
}

ul.ui-autocomplete {
	width     : 279px;
	list-style: none;
}

legend.trainlines a {
	color     : #000;
	visibility: hidden;
}

legend.trainlines:hover a {
	visibility: visible;
}

ol.train_lines li a {
	color     : #000;
	visibility: hidden;
}

ol.train_lines li:hover a {
	visibility: visible;
}

#results_list_sortable div.gridview {
	display: none;
}

#results .no-results {
	height         : 180px;
	display        : flex;
	flex-direction : column;
	justify-content: center;
}

#results .no-results h3 {
	margin-bottom: 20px;
}

@media print {
	body {
		padding-top: 0;
	}

	div.actionsBar,
	div.row>div.form-actions,
	.d-print-none {
		display: none !important;
	}

	div.row {
		margin-bottom: 1px;
	}

	input[name="custom_id"],
	input[name="custom_date"] {
		font-size: 15px;
	}

	input,
	textarea,
	select {
		border    : none;
		box-shadow: none;
		padding   : 0 0 0 4px;
		box-sizing: border-box;
		overflow  : visible;
	}

	input {
		height: auto;
	}

	textarea {
		height: auto;
	}

	select {
		appearance: none;
		padding : 0;
	}
}

.inputs-list.inline li {
	float  : left;
	width  : auto;
	padding: 0 3px 0 3px;
}

input:invalid:not([hide-invalid]) {
	background-color: #f2dede;
	border-color    : #b94a48;
	color           : #b94a48;
}

span.unpaid {
	color: red;
	/*#0064cd;*/
}

canvas.chart {
	margin : 5px auto;
	display: block;
}

.color.good {
	color: #8CC63E;
}

.color.bad {
	color: #93361F;
}

.badge-outline-primary{
	color: #007bff;
    background-color: #fff;
	border: 1px solid #007bff;
}


/**
 * Twitter Typeahead CSS from more recent versions of Bootstrap
 */

.tt-input {
	font-family: 'Helvetica Neue', Helvetica, Arial, FontAwesome, sans-serif;
	/* Needed for FontAwesome placeholders */
	vertical-align                             : initial !important;
	/* Needed as typeahead sets "vertical-align: top" in element style, which breaks Bootstrap */
}

.tt-hint,
.tt-disabled {
	color: #888;
}

.tt-menu {
	width           : 100%;
	padding         : 8px 0;
	background-color: #fff;
	border          : 1px solid #ccc;
	border          : 1px solid rgba(0, 0, 0, 0.2);
}

.tt-suggestion,
.tt-empty {
	padding: 3px 8px;
}

.tt-suggestion.tt-cursor {
	color           : #fff;
	background-color: #0097cf;
}

#kpiList .card-header {
	padding: 0;
}

.btn.btn-round {
	border-radius: 50%;
}

.motozuke-row, .daicho-row {
	border-top: 2px solid #dee2e6;
}

.badge-suumo {
	color: #fff;
	background-color: #61aa12;
}

.badge-yahoo {
	color: #fff;
	background-color: #ff0033;
}

.badge-edm {
	color: #fff;
	background-color: #7856ff;
}

.badge-hj {
	color: #fff;
	background-color: #0055ff;
}

.badge-sns {
	color: #fff;
	background-color: #cd486b;
}

.badge-reins {
	background-color: #e2f0d9;
}

.badge-rakumachi {
	color: #fff;
	background-color: #211816;
}

.modal-body {
	overflow: scroll;
}

/* Make tab text red if tab contains field with invalid pseudoclass */
.nav-tabs:has(~ .tab-content #tab-building-basics :invalid) a[href="#tab-building-basics"],
.nav-tabs:has(~ .tab-content #tab-building-whole-building-analysis :invalid) a[href="#tab-building-whole-building-analysis"],
.nav-tabs:has(~ .tab-content #tab-building-fees :invalid) a[href="#tab-building-fees"],
.nav-tabs:has(~ .tab-content #tab-building-features :invalid) a[href="#tab-building-features"],
.nav-tabs:has(~ .tab-content #tab-building-campaigns :invalid) a[href="#tab-building-campaigns"],
.nav-tabs:has(~ .tab-content #tab-building-media :invalid) a[href="#tab-building-media"],
.nav-tabs:has(~ .tab-content #tab-building-admin :invalid) a[href="#tab-building-admin"],
.nav-tabs:has(~ .tab-content #tab-building-pm :invalid) a[href="#tab-building-pm"],
.nav-tabs:has(~ .tab-content #tab-unit-basics :invalid) a[href="#tab-unit-basics"],
.nav-tabs:has(~ .tab-content #tab-unit-fees :invalid) a[href="#tab-unit-fees"],
.nav-tabs:has(~ .tab-content #tab-unit-features :invalid) a[href="#tab-unit-features"],
.nav-tabs:has(~ .tab-content #tab-unit-campaigns :invalid) a[href="#tab-unit-campaigns"],
.nav-tabs:has(~ .tab-content #tab-unit-media :invalid) a[href="#tab-unit-media"],
.nav-tabs:has(~ .tab-content #tab-unit-admin :invalid) a[href="#tab-unit-admin"],
.nav-tabs:has(~ .tab-content #tab-unit-pm :invalid) a[href="#tab-unit-pm"] {
	color: #dc3545 !important;		/* text-danger */
}

.status {
	color: #fff;
	text-transform: capitalize;

	padding: 2px 8px;
	border-radius: 8px;
}

.status-active {
	background-color: #61aa12;
}

.status-pending {
	color: #000;
	background-color: #edbe00;
}

.status-deleted {
	background-color: #c60b0b;
}

#automatch_result {
	overflow: scroll;
}

.link-criteria,
.link-event {
	cursor: pointer;
}
